<template>
	<!-- Gestions des liens -->
	<div v-if="customConf.routeName" class="d-inline" :class="customClasses" @click.stop>
		<!-- Liens basés sur le href de l'item -->
		<a v-if="autoConstructUrl" :href="data.item.href + data.item.id">
			<span v-if="customConf.rawHtml" v-html="cleanHTML(data.item.label)"></span>
			<span v-else>{{ data.item.label }}</span>
		</a>
		<!-- Liens basés sur la prop hrefsRoutes -->
		<router-link v-else-if="data.item['route_'+customConf.routeUniqueName]" :to="{ name: customConf.routeName, params: data.item['route_'+customConf.routeUniqueName] }">
			<span v-if="customConf.rawHtml" v-html="cleanHTML(data.value)"></span>
			<span v-else>{{ data.value }}</span>
		</router-link>
		<span v-else>
			{{ data.value }}
		</span>
	</div>
	<!-- Gestion des slots sans liens -->
	<div v-else :class="customClasses">
		<!-- RawHtml + Callback -->
		<template v-if="customConf.rawHtml && customConf.callback">
			<span v-html="cleanHTML(data.value)" @click.prevent="customConf.callback(data.item)"></span>
		</template>
		<!-- RawHtml + Preview -->
		<template v-else-if="customConf.rawHtml && customConf.preview">
			<span v-html="cleanHTML(data.value)" :class="[previewColClass, previewColClass+'__'+colName]"></span>
		</template>
		<!-- RawHtml only -->
		<template v-else-if="customConf.rawHtml">
			<span v-html="cleanHTML(data.value)"></span>
		</template>
		<!-- Callback only -->
		<template v-else-if="customConf.callback">
			<a href="#" @click.prevent="customConf.callback(data.item)" >{{ data.value }}</a>
		</template>
		<!-- Preview only -->
		<template v-else-if="customConf.preview">
			<span :class="[previewColClass, previewColClass+'__'+colName]">
				{{ data.value }}
				<font-awesome-icon :class="[previewColClass, previewColClass+'__'+colName]" :icon="['fas', 'eye']" />
			</span>
		</template>
	</div>
</template>

<script>
import Table from '@/mixins/Table'
import xss from 'xss'

export default {
	name: 'ItemCell',
	mixins: [Table],
	props: {
		customConf: Object,
		colName: String,
		data: Object,
		previewColClass: String,
		autoConstructUrl: Boolean
	},
	methods: {
		cleanHTML(input) {
			return xss(input)
		}
	},
	computed: {
		customClasses() {
			const col_name = (this.colName.replace('cell(', '')).replace(')', '')
			const line_has_c_class = this.data.item.customClasses || {}
			return line_has_c_class[col_name] || {}
		}
	}
}
</script>
